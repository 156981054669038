import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {TooltipPosition} from '@angular/material/tooltip';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConexionService} from '../services/conexion.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


export class Myerror implements ErrorStateMatcher{
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null)
  : boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted))
  }


}

interface Alert{
  type:string;
  message:string;
}

interface Producto {
  value: string;
  viewValue: string;
}

interface GrupoP {
  disabled?: boolean;
  name: string;
  product: Producto[];
}

interface Impreso{
  value: string;
  viewValue: string;
}

const Alerts:Alert[] = [{
  type:'danger',
  message:'Recuerda diligenciar todos los campos.'
}]
@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss']
})
export class CotizarComponent implements OnInit {
  /** variables */
  email:any;
  phone:any;
  name:any;
  company:any
  alto:any;
  largo:any;
  ancho:any;
  peso:any;
  cantidad:any;
  largoEdit:any;
  anchoEdit:any;
  altoEdit:any;
  pesoEdit:any;
  cantidadEdit:any;
  /*Arrays*/
  medidas:any = [];
  ArrayEdit:any = {};
  alerts: Alert[];
  cotizacion:any = [];
  /* Group */
  profile: FormGroup;
  
  impreso: Impreso[] = [
    {value: 'Si', viewValue:'Si'},
    {value: 'No', viewValue: 'No'}
  ]

  grupoControler: GrupoP[] = [
    {
      name: 'Cajas regulares',
      product: [
        {value: 'Regular 4 alas', viewValue: 'Regular 4 alas'},
        {value: 'Traslapadas', viewValue: 'Traslapadas'},
        {value: 'Media regulares', viewValue: 'Media regulares'},
        {value: 'Tipo contenedor', viewValue: 'Tipo contenedor'},
        {value: 'Telescopica', viewValue: 'Telescopica'},
        {value: 'Caja de segunda', viewValue: 'Caja de segunda'}
      ]
    },
    {
      name: 'Cajas para frutas',
      product: [
        {value: 'Cajas para frutas', viewValue: 'Cajas para frutas'}
      ]
    },
    {
      name: 'Cajas troqueladas',
      product: [
        {value: 'Cajas tipo cofre', viewValue: 'Cajas tipo cofre'},
        {value: 'Tipo maletin', viewValue: 'Tipo maletin'},
        {value: 'Para ancheta', viewValue: 'Para ancheta'},
        {value: 'Caja para mugs', viewValue: 'Caja para mugs'},
        {value: 'Diseños especiales', viewValue: 'Diseños especiales'}
      ]
    },
    {
      name: 'Cajas colaminadas',
      product: [
        {value: 'Full color', viewValue: 'Full color'},
      ]
    },
    {
      name: 'Laminas de carton',
      product: [
        {value: 'Microcorrugado', viewValue: 'Microcorrugado'},
        {value: 'Doble pared', viewValue: 'Doble pared'},
        {value: 'Pared sencilla', viewValue: 'Pared sencilla'}
      ]
    },
    {
      name: 'Cajas para pizza',
      product: [
        {value: 'Cajas para pizza cuadrada', viewValue: 'Cajas para pizza cuadrada'},
        {value: 'Cajas para pizza esquinas exagonales', viewValue: 'Cajas para pizza esquinas exagonales'},
        {value: 'Diseños especiales', viewValue: 'Diseños especiales'}
      ]
    },
    {
      name: 'Cajas para archivo',
      product: [
        {value: 'Ref. X100', viewValue: 'Ref. X100'},
        {value: 'Ref. X200', viewValue: 'Ref. X200'},
        {value: 'Ref. X300', viewValue: 'Ref. X300'},
        {value: 'Ref. X300 reforzada', viewValue: 'Ref. X300 reforzada'},

      ]
    },
    {
      name: 'Carpetas para archivo',
      product: [
        {value: 'Tapas legajadoras', viewValue: 'Tapas legajadoras'},
        {value: '4 alas en yute', viewValue: '4 alas en yute'},
        {value: '4 alas en propalcote', viewValue: '4 alas en propalcote'},
        {value: 'Celugia', viewValue: 'Tapas legajadoras'},
        {value: 'Carpetas multifolder', viewValue: 'Carpetas multifolder'},
      ]
    },
    {
      name: 'Ganchos legajadores',
      product: [
        {value: 'Ganchos legajadores', viewValue: 'Ganchos legajadores'},
      ]
    },
    {
      name: 'Cajas plegadizas',
      product: [
        {value: 'Full color', viewValue: 'Full color'},
      ]
    },
    {
      name: 'Cajas plegadizas',
      product: [
        {value: 'Cajas plegadizas', viewValue: 'Cajas plegadizas'},
      ]
    },
    {
      name: 'Cajas de lujo',
      product: [
        {value: 'Tipo corazón', viewValue: 'Tipo corazón'},
        {value: 'Cilindricas', viewValue: 'Cilindricas'},
        {value: 'Tipo libro', viewValue: 'Tipo libro'},
        {value: 'Tapa y base para zapatos', viewValue: 'Tapa y base para zapatos'}
      ]
    },
    {
      name: 'Cajas E-commerce',
      product: [
        {value: 'Cajas E-commerce', viewValue: 'Cajas E-commerce'},
      ]
    },
    {
      name: 'Complementos para empaque y embalaje',
      product: [
        {value: 'Divisiones', viewValue: 'Divisiones'},
        {value: 'Particiones', viewValue: 'Particiones'},
        {value: 'Esquineros', viewValue: 'Esquineros'},
        {value: 'Plastico Stretch', viewValue: 'Plastico Stretch'},
        {value: 'Cinta', viewValue: 'Cinta'},
        {value: 'Suncho', viewValue: 'Suncho'},
        {value: 'Grapa', viewValue: 'Grapa'},
        {value: 'Carton singel face', viewValue: 'Carton singel face'}
      ]
    },
    {
      name: 'Bolsas en papel',
      product: [
        {value: 'Con manija entorchada', viewValue: 'Con manija entorchada'},
        {value: 'Con manija troquelada', viewValue: 'Con manija troquelada'},
        {value: 'Con manija en tela', viewValue: 'Con manija en tela'},
        {value: 'Con manija en cordón', viewValue: 'Con manija en cordón'},
        {value: 'Bolsas impresas', viewValue: 'Bolsas impresas'}
      ]
    },
    {
      name: 'Complementos para empaque y embalaje',
      product: [
        {value: 'Divisiones', viewValue: 'Divisiones'},
        {value: 'Particiones', viewValue: 'Particiones'},
        {value: 'Esquineros', viewValue: 'Esquineros'},
        {value: 'Plastico Stretch', viewValue: 'Plastico Stretch'},
        {value: 'Cinta', viewValue: 'Cinta'},
        {value: 'Suncho', viewValue: 'Suncho'},
        {value: 'Grapa', viewValue: 'Grapa'},
        {value: 'Carton singel face', viewValue: 'Carton singel face'}
      ]
    },
    {
      name: 'Rascadores',
      product: [
        {value: 'Rectangulares', viewValue: 'Rectangulares'},
        {value: 'Tipo ola', viewValue: 'Tipo ola'},
        {value: 'Sillón pequeño', viewValue: 'Sillón pequeño'},
        {value: 'Sillón grande', viewValue: 'Sillón grande'},
        {value: 'Infinito', viewValue: 'Infinito'},
        {value: 'Rectangulares pequeños', viewValue: 'Rectangulares pequeños'},
        {value: 'Triangular', viewValue: 'Triangular'}
      ]
    }
  ];


  constructor(private conexionservice: ConexionService, private formBuilder:FormBuilder, config:NgbModalConfig, private modalConfig: NgbModal){
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(){
  
    this.largoEdit = new FormControl('',[Validators.required]);
    this.anchoEdit = new FormControl('',[Validators.required]);
    this.altoEdit = new FormControl('',[Validators.required]);
    this.pesoEdit = new FormControl('',[Validators.required]);
    this.cantidadEdit = new FormControl('',[Validators.required]);

    this.email = new FormControl('',[Validators.email])
    this.phone = new FormControl('',[])
    this.name = new FormControl('',[])
    this.company = new FormControl()

    this.profile = this.formBuilder.group({
      largo: new FormControl ('', [Validators.pattern(/^([0-9\,\.]+?[0-9]{0,4})$/)]),
      alto: new FormControl ('', [Validators.pattern(/^([0-9\,\.]+?[0-9]{0,4})$/)]),
      ancho: new FormControl ('', [Validators.pattern(/^([0-9\,\.]+?[0-9]{0,4})$/)]),
      peso: new FormControl ('', [Validators.pattern(/^([0-9\,\.]+?[0-9]{0,4})$/)]),
      cantidad: new FormControl ('', [Validators.pattern(/^([0-9\,\.]+?[0-9]{0,4})$/)]),
      selectProducto: new FormControl (""),
      selectImpreso:new FormControl("")
    })

  }
  
  getErrorMessageEmail(){
    if (this.email.hasError('required') || this.email.hasError('invalid')) {
      return 'Se debe agregar un correo electrónico';
    }
    return this.email.hasError('email') ? 'Correo No Valido' : '';
  }
  
  getErrorMessagePhone(){
    if (this.phone.hasError('required') || this.phone.hasError('invalid')) {
      return 'Se debe agregar un número de contacto';
    }
  }

  getErrorMessageName(){
    if (this.name.hasError('required') || this.name.hasError('invalid')) {
      return 'Se debe agregar un nombre';
    }
    return this.email.hasError('email') ? 'Correo No Valido' : '';
  }


  get f() {
    return this.profile.controls; 
  }


  validar(){
    let medida:any = [];
    let alto = this.profile.controls.alto.value;
    let largo = this.profile.controls.largo.value;
    let ancho = this.profile.controls.ancho.value;
    let peso = this.profile.controls.peso.value;
    let cantidad = this.profile.controls.cantidad.value;
    let select = this.profile.controls.selectProducto.value;
    let selectImpreso = this.profile.controls.selectImpreso.value;

    medida.push({
      "largo":largo,
      "ancho":ancho,
      "alto":alto,
      "peso":peso,
      "cantidad":cantidad,
      "select":select,
      "impreso": selectImpreso
    })
    console.log(medida)
    console.log(this.profile.status)
    console.log(this.profile)
    console.log(select)
    if(this.profile.status != "INVALID"){
      for(let i = 0; i < medida.length; i++){
        if(medida[i].largo == "" || medida[i].ancho == "" || medida[i].alto == "" || medida[i].peso == "" || medida[i].cantidad == "" || medida[i].impreso == ""  || medida[i].impreso == null || medida[i].select == ""  || medida[i].select == null || medida[i].largo == null || medida[i].ancho == null || medida[i].alto == null || medida[i].peso == null || medida[i].cantidad == null){
          Swal.fire({
            text: 'Recuerda que todos los campos son obligatorios para agregar la referencia a la cotización',
            icon: 'error'
          });
        }else{
          this.medidas.push({
            'id':this.medidas.length,
            'largo':medida[i].largo,
            "ancho":medida[i].ancho,
            "alto":medida[i].alto,
            "peso":medida[i].peso,
            "cantidad":medida[i].cantidad,
            "select":medida[i].select,
            "impreso":medida[i].impreso
          })
          this.Borrar()
        }
      }
    }else if(this.profile.status == "INVALID"){
      this.Borrar();
    }
  }

  Btneditar(id:any,content){
    this.modalConfig.open(content)
    console.log(id)

    for(let i = 0; i < this.medidas.length; i++){
      if(this.medidas[i].id === id){
        this.ArrayEdit = {
          'id':id,
          'largo':this.medidas[i].largo,
          "ancho":this.medidas[i].ancho,
          "alto":this.medidas[i].alto,
          "peso":this.medidas[i].peso,
          "cantidad":this.medidas[i].cantidad
        }
      }      
    }
  }


  editar(content,i,l,an,a,p,c){
    let alto = a;
    let largo = l;
    let ancho = an;
    let peso = p;
    let id = i;
    let cantidad = c;

    this.medidas.map(function(dato){
      if(dato.id === id){
        dato.largo = largo,
        dato.ancho = ancho,
        dato.alto = alto,
        dato.peso = peso,
        dato.cantidad = cantidad
      }
    })
    this.cerrarModal(content);
  }

  BtnEliminar(id){
    for(let i = 0; i < this.medidas.length; i++){
      let index;
      if(this.medidas[i].id === id){
        index = this.medidas.indexOf(this.medidas[i]);
        this.medidas.splice(index, 1);

        
        Swal.fire({
          text: 'Se elimino con éxito',
          icon: 'success'
        });
      }      
    }
  }
  
  cotizar(){
    let enviar = [];
    let cotiza:any = [];
    let name = this.name.value;
    let company = this.company.value;
    let email = this.email.value;
    let phone = this.phone.value;

    if(this.medidas.length === 0){
      Swal.fire({
        text: 'Recuerda que debes agregar las referencias para la cotización',
        icon: 'error'
      });


    }else if (name == "" || company == "" || email == "" || phone == "" || name == null || company == null || email == null || phone == null ){
      Swal.fire({
        text: 'Recuerda que debes agregar todos tus datos personales',
        icon: 'error'
      });
    }else{

      enviar.push({
        name:name,
        company:company,
        email:email,
        phone:phone,
        cotizacion:this.medidas
      })
      console.log(enviar)

      this.profile.reset();
      this.name.reset();
      this.company.reset();
      this.email.reset();
      this.phone.reset();
      this.medidas = []
      this.conexionservice.addData(enviar).subscribe(
        datos=>{
          console.log(datos)
          Swal.fire({
            text: 'Recibira un correo electronico con los detalles de la cotización y en un plazo de 8 días te enviaremos la cotización final',
            icon: 'success'
          });
        }
      )
    }
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  Borrar(){
    this.profile.reset();
  }

  cerrarModal(content){
    this.modalConfig.dismissAll(content)
    this.ArrayEdit = []
  }
  matcher = new Myerror(); 

}
