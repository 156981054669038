<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Cotización</title>
</head>
<body>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img src="https://procolpack.com/wp-content/uploads/2021/01/cropped-1000-x-1000-Logo-Procolpack-e1611699720489-300x93.png" width="200px" class="navbar-brand" href="#"/>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: flex-end;">
        <ul class="navbar-nav mb-2 mb-lg-0" >
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/cajas-e-commerce/">Cajas ecommerce</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/cajas-plegadizas/">Cajas plegadizas</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/empaques-en-carton-corrugado/">Cartón corrugado</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/gestion-documental/">Gestión documental</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/bolsas-en-papel/">Bolsas en papel</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/categoria/rascadores-para-gatos/">Rascadores de gatos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://procolpack.com/tienda/">Productos</a>
          </li>
          <button type="button" class="btn btn-outline-primary btnContact"><a href="https://procolpack.com/contacto/  " > Contáctenos </a></button>
        </ul>
      </div>
    </div>
  </nav>


  <div class="banner">
    <div class="bgbanner">
      <div class="text-content">
        <p>Crr 69g 70-34 bodega primer piso | Teléfono 6410116 | <a href="mailt o:administracion@procolpack.com">administracion@procolpack.com</a></p>
      </div>
    </div>

  </div>
  <div class="tittle">
    <h1>Recibirá una cotización a su correo de entrega</h1>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="texto">
          <p>
            Para realizar su cotización es importante que establezca el tamaño del producto a empacar y su peso, para determinar el tipo de cartón y resistencia adecuada de su caja.
          </p>
          <p>
            Con esta información usted recibirá de manera inmediata a su correo electrónico la cotización de sus cajas con y sin impresión de su imagen corporativa, así como las alternativas de entrega en 8 días y en 48 horas, para que pueda determinar su elección, un asesor lo contactará para atender su requerimiento.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="image">
          <img src="../../assets/image/caja-cotizador-300x229.png" alt="" srcset="">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3 class="title-medidas">Proporciona las medidas de tus caja, peso y cantidad y haz click en "Agregar Referencia" para continuar.</h3>
        <form (ngSubmit)="validar()" class="form-medidas" [formGroup]="profile" method="POST">
          <div class="container" style="margin-bottom: 20px;">
            <div class="row">
              <div class="col-md-6">
                <div class="select">
                  <h1>Seleccione la categoria a cotizar</h1>
                  <mat-form-field class="selectP" appearance="fill">
                    <mat-label>Seleccione producto</mat-label>
                    <mat-select  matInput formControlName="selectProducto">
                      <mat-option>-- Ninguno --</mat-option>
                      <mat-optgroup  *ngFor="let group of grupoControler" [label]="group.name"
                                    [disabled]="group.disabled">
                        <mat-option *ngFor="let product of group.product" [value]="group.name + '&nbsp;' + product.value">
                          {{product.viewValue}}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                  </mat-form-field>

                  <p><a href="https://procolpack.com/tienda/">*Si quieres saber más de las categorias da clic aquí*</a></p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="select">
                  <h1>Requiere impresión</h1>
                  <mat-form-field class="selectP" appearance="fill">
                    <mat-label>Seleccione una opción</mat-label>
                    <mat-select  matInput formControlName="selectImpreso">
                      <mat-option>-- Ninguna --</mat-option>
                      <mat-option *ngFor="let impreso of impreso" [value]="impreso.value">
                        {{impreso.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div  id="medidas">
            <mat-form-field class="example-full-width">
              <mat-label>Largo</mat-label>
              <input matTooltip="Info about the action"
              name="largo" type="text" matInput formControlName="largo" placement="bottom" ngbTooltip="Medida en Centimetros">
              <mat-error *ngIf="f.largo.errors">Solo se permite agregar números, comas y puntos</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Ancho</mat-label>
                <input matTooltip="Info about the action"
                name="ancho" type="text" matInput formControlName="ancho"  placement="bottom" ngbTooltip="Medida en Centimetros">
                <mat-error *ngIf="f.ancho.errors">Solo se permite agregar números, comas y puntos</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Alto</mat-label>
                <input matTooltip="Info about the action"
                name="alto" type="text" matInput formControlName="alto"  placement="bottom" ngbTooltip="Medida en Centimetros">
                <mat-error *ngIf="f.alto.errors" >Solo se permite agregar números, comas y puntos</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Peso</mat-label>
                <input matTooltip="Info about the action"
                name="peso" type="text" matInput formControlName="peso" placement="bottom" ngbTooltip="Peso en KG">
                <mat-error *ngIf="f.peso.errors">Solo se permite agregar números, comas y puntos</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Cantidad</mat-label>
                <input matTooltip="Info about the action"
                name="cantidad" type="text" matInput formControlName="cantidad"  placement="bottom" ngbTooltip="Unidad">
                <mat-error *ngIf="f.cantidad.errors">Solo se permite agregar números, comas y puntos</mat-error>
            </mat-form-field>
            
          </div>
          <div id="buttonR">
            <p *ngFor="let alert of alerts">
              <ngb-alert [type]="alert.type" (closed)="close(alert)">{{ alert.message }}</ngb-alert>
            </p>
            <button class="btn btn-primary" type="submit" id="btnR">Agregar Referencia</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="tableMedidas">
    <form action="" *ngIf="medidas.length > 0; let i = index" class="tableContainer">
      <div class=" table-responsive">
        <table class="table table-bordered table-hover ">
            <thead>
              <tr>
                <th scope="col">Referencia</th>
                <th scope="col">Categorias</th>
                <th scope="col">Impreso</th>
                <th scope="col">Largo</th>
                <th scope="col">Ancho</th>
                <th scope="col">Alto</th>
                <th scope="col">Peso</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Editar</th>
                <th scope="col">Eliminar</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let medida of medidas">
                <td>{{medida.id + 1}}</td>
                <td>{{medida.select}}</td>
                <td>{{medida.impreso}}</td>
                <td>{{medida.largo}}</td>
                <td>{{medida.ancho}}</td>
                <td>{{medida.alto}}</td> 
                <td>{{medida.peso}}</td>
                <td>{{medida.cantidad}}</td>
                <td>
                    <button type="button" (click)="Btneditar(medida.id, content)" data-bs-toggle="modal" data-bs-target="#modalEditar" class="btn btn-success">Editar</button>
                </td>
                <td>
                    <button type="button" (click)="BtnEliminar(medida.id)" class="btn btn-danger">Eliminar</button>
                </td>
            </tr>
            </tbody>
        </table>
        <p class="p-medidas">Puedes hacer ajustes a los valores ya ingresados (haz click en Editar para modificar datos ingresados) o elimina las filas según tus necesidades.</p>
      </div>
    </form>
  </div>
  
  <div class="container">
    <div class="form-datos">
      <h3 class="title-datos">Proporciona tus datos personales</h3>
      <form action="" (ngSubmit)="cotizar()">
        <mat-form-field class="example-full-width">
          <mat-label>Nombre</mat-label>
          <input type="text" matInput [formControl]="name">
          <mat-error *ngIf="name.invalid">{{getErrorMessageName()}}</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Empresa</mat-label>
          <input type="text" matInput [formControl]="company">
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Número Telefónico</mat-label>
          <input type="text" minlength="7" maxlength="10" matInput [formControl]="phone"  placeholder="Fijo o celular">
          <mat-error *ngIf="phone.invalid">{{getErrorMessagePhone()}}</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input matTooltip="Info about the action"
          type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
          <mat-error *ngIf="email.invalid">{{getErrorMessageEmail()}}</mat-error>
        </mat-form-field>

        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="texto">
                <p>
                  <i class="fas fa-angle-right"></i> De acuerdo a su información, la cotización le llegará de manera inmediata su correo electrónico.
                </p>
                <p>
                  <i class="fas fa-angle-right"></i> La cotización incluye la entrega a domicilio, después 400 unidades, solo para Bogotá.
                </p>
                <p>
                  <i class="fas fa-angle-right"></i> Para cantidades inferiores a 400 unidades, la entrega tendrá costo adicional por el flete de envío o podrán ser recogidas en nuestras instalaciones.
                </p>
                <p>
                  <i class="fas fa-angle-right"></i> Para otro tipo de cajas o desarrollos especiales, por favor <a href="https://procolpack.com/contacto/"> contáctenos. </a>
                </p>
                <p>
                  <i class="fas fa-angle-right"></i> Para despachos nacionales se coordinara el flete con una transportadora con cargo al cliente.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary btnCotizar">Cotizar</button>
      </form>
    </div>
  </div>



  
<!-- Modal Editar -->

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <div>
      <h4 class="modal-title" id="modal-basic-title">Medidas de referencia {{ArrayEdit.id + 1}}</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="cerrarModal(content)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" #dataForm = "ngForm" class="formModal">
      <div class="modal-form">
        <mat-form-field class="example-full-width modalF" style="margin-right: 10px;">
          <mat-label>Largo</mat-label>
          <input matTooltip="Info about the action"
          type="number" matInput  name="ArrayEdit.largo"  [(ngModel)]="ArrayEdit.largo" [value]="ArrayEdit.largo" #largo="ngModel" required value=" edit.largo ">
        </mat-form-field>
        <mat-form-field class="example-full-width modalF" style="margin-right: 10px;" >
            <mat-label>Ancho</mat-label>
            <input matTooltip="Info about the action"
            type="number" matInput name="ArrayEdit.ancho" [(ngModel)]="ArrayEdit.ancho" [value]="ArrayEdit.ancho" #ancho="ngModel" required value=" edit.ancho ">
        </mat-form-field>
        <mat-form-field class="example-full-width modalF" style="margin-right: 10px;">
            <mat-label>Alto</mat-label>
            <input matTooltip="Info about the action"
            type="number" matInput name="ArrayEdit.alto" [(ngModel)]="ArrayEdit.alto" [value]="ArrayEdit.alto" #alto="ngModel" required value=" edit.alto ">
        </mat-form-field>
        <mat-form-field class="example-full-width modalF" style="margin-right: 10px;">
            <mat-label>Peso</mat-label>
            <input matTooltip="Info about the action"
            type="number" matInput name="ArrayEdit.peso" [(ngModel)]="ArrayEdit.peso" [value]="ArrayEdit.peso" #peso="ngModel" required value=" edit.peso ">
        </mat-form-field>
        <mat-form-field class="example-full-width modalF" style="margin-right: 10px;">
            <mat-label>Cantidad</mat-label>
            <input matTooltip="Info about the action"
            type="number" matInput name="ArrayEdit.cantidad" [(ngModel)]="ArrayEdit.cantidad" [value]="ArrayEdit.cantidad" #cantidad="ngModel" required value=" edit.cantidad ">
        </mat-form-field>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="editar(content,ArrayEdit.id, ArrayEdit.largo,ArrayEdit.ancho,ArrayEdit.alto,ArrayEdit.peso,ArrayEdit.cantidad)">Guardar</button>
  </div>
</ng-template>

  
</body>
</html>
  


