import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConexionService {
  url = environment.baseUrl;
  constructor(private http:HttpClient) { }

  addData(data){
    console.log(data)
    return this.http.post(`${this.url}/sendmail`, data) 
  }
}
